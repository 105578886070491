
import { exifFromCapacitor } from '@/components/ImageInput.vue';
import SteppedPostCreationForm from '@/components/SteppedPostCreationForm.vue';
import RouteNames from '@/router/names';
import { DraftPost, Investigation, LngLat, MBAddressObj, PostCreationPayload, QuestionDataType, StructuredAnswer } from '@/types';
import { getActiveLocation } from '@/util.location';
import { parseAddressComponentsFromMBobj, reverseGeocode } from '@/util.mapbox';
import Vue from '@/vueTyped';
import ExifReader from 'exifreader';
import localForage from 'localforage';
import debounce from 'lodash/debounce';
import moment from 'moment';

export type PhotoMetadata = {
  date: string;
  time: string;
  location: MBAddressObj | null;
  coordinates: LngLat | null;
};

const STRUCTURED_ANSWER_VALUE_KEYS = {
  [QuestionDataType.DECIMAL]: 'numericValue',
  [QuestionDataType.NUMERIC]: 'numericValue',
  [QuestionDataType.BOOLEAN]: 'booleanValue',
  [QuestionDataType.DATETIME]: 'datetimeValue',
  [QuestionDataType.SELECT]: 'literalValue',
  [QuestionDataType.TIMERANGE]: 'timeRangeValue',
};

export default Vue.extend({
  name: 'PostsCreate',

  metaInfo: {
    title: 'Create a Post',
  },

  components: {
    SteppedPostCreationForm,
  },

  props: {
    investigationIdFromQuery: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      postData: {} as DraftPost,
      photoMetadata: {
        date: '',
        time: '',
        location: null as MBAddressObj | null,
        coordinates: null as LngLat | null,
      } as PhotoMetadata,
      confirmingReset: false,
      saving: false,
      sizePosted: 0,
      errors: {
        noText: false,
        noDate: false,
        noTime: false,
        noLocation: false,
        noCoordinates: false,
      },
    };
  },

  computed: {
    investigations(): Investigation[] {
        return this.$store.state.investigations.items;
    },

    currentUserId(): string {
      return this.$store.state.account.currentUser?.id ?? '';
    },

    draftKeyPrefix(): string {
      return `draft-sightings/${this.currentUserId}/`;
    },

    backPosted(): string {
      if (this.postData.date === undefined) {
        return '';
      }

      const now = moment();
      const observedAt = moment(`${this.postData.date} ${this.postData.time}`);
      const fromPast = now.diff(observedAt, 'minutes') >= 15;
      if (fromPast) {
        return observedAt.from(now);
      } else {
        return '';
      }
    },

    futurePosted(): boolean {
      if (this.postData.date === undefined) {
        return false;
      }

      const now = moment();
      const observedAt = moment(`${this.postData.date} ${this.postData.time}`);
      return observedAt.diff(now) > 0;
    },

    formattedStructuredAnswers(): StructuredAnswer[] {
      const structuredAnswers = [];

      const selectedInvestigations = this.investigations.filter(investigation => this.postData.investigationIds.includes(investigation.id));

      for (const investigation of selectedInvestigations) {
        for (const question of investigation.structuredQuestions) {
          const answer = this.postData.structuredAnswers[question.id];

          if (answer) {
            const answerKey = STRUCTURED_ANSWER_VALUE_KEYS[question.dataType];
            let answerValue;

            switch(question.dataType) {
              case QuestionDataType.BOOLEAN:
              case QuestionDataType.SELECT:
                if (Array.isArray(answer)) {
                  // Send arrays as JSON (the back end can't handle arrays right now).
                  answerValue = JSON.stringify(answer);
                } else if (answer && Object.prototype.hasOwnProperty.call(answer, 'value')) {
                  answerValue = answer.value;
                } else {
                  answerValue = answer;
                }
                break;
              case QuestionDataType.DECIMAL:
              case QuestionDataType.NUMERIC:
                answerValue = parseFloat(answer);
                break;
              case QuestionDataType.DATETIME:
                // eslint-disable-next-line no-case-declarations
                const [hour, minute] = answer.split(':');
                answerValue = moment(this.postData.date).hour(hour).minute(minute).format();
                break;
              case QuestionDataType.TIMERANGE:
                // Don't send [null, null].
                if (answer.some(Boolean)) {
                  answerValue = answer.map((value: string | null) => value ? moment(value).format() : null);
                }
            }

            if (answerValue !== null && answerValue !== undefined) {
              structuredAnswers.push({
                questionId: question.id,
                [answerKey]: answerValue
              } as StructuredAnswer);
            }
          }
        }
      }

      return structuredAnswers;
    },

    editorProps(): any {
      const payloadSize = this.postData.photos.reduce((total, file) => {
        return total + file.size;
      }, 1); // Start at 1 to represent the sighting post itself.

      return {
        investigations: this.investigations,
        post: this.postData,
        photoMetadata: this.photoMetadata,
        backPosted: this.backPosted,
        errors: this.errors,
        saving: this.saving,
        saveProgress: Math.min(1, this.sizePosted / payloadSize),
      };
    },

    successMessage(): string {
      if (this.postData.investigationIds.length === 0) {
        return this.$t('steppedPostCreation.success') as string;
      } else {
        const investigationLinks = this.postData.investigationIds.map(investigationId => {
          const investigation = this.investigations.find(investigation => investigation.id === investigationId);
          if (investigation) {
            const route = this.$router.resolve({ name: RouteNames.INVESTIGATION_POSTS, params: { id: investigation?.id } });
            return `[${investigation?.name}](${route.href})`;
          } else {
              return '';
          }
        });

        const linkJoiner = investigationLinks.some(link => link.includes(',')) ? '; ' : ', ';

        return [
          this.$t('steppedPostCreation.success'),
          this.$t('steppedPostCreation.successLinks', { links: investigationLinks.join(linkJoiner) })
        ].join(' ');
      }
    },
  },

  watch: {
    investigationIdFromQuery(investigationIdFromQuery) {
      if (investigationIdFromQuery && !this.postData.investigationIds.includes(investigationIdFromQuery)) {
        this.postData.investigationIds.push(investigationIdFromQuery);
      }
    },

    currentUserId() {
      this.loadLatestOrCreateNewDraft();
    },

    postData: {
      deep: true,
      handler: debounce(function(this: any) {
        this.storeCurrentState();
      }, 250),
    },

    async 'postData.photos'(photos: File[], oldPhotos?: File[]) {
      const newlyMounted = oldPhotos === undefined;

      this.photoMetadata.date = '';
      this.photoMetadata.time = '';
      this.photoMetadata.location = null;
      this.photoMetadata.coordinates = null;

      for (const photo of photos) {
        if (!photo.type.startsWith('image/')) {
          continue;
        }

        if (this.photoMetadata.date && this.photoMetadata.coordinates) {
          break;
        }

        try {
          const capacitorExif = exifFromCapacitor.get(photo) ?? {};
          const exifReaderTags = await ExifReader.load(photo as any, { expanded: true });

          // Prefer Capacitor's EXIF data, since it's possibly more complete.
          const knownTags = {
            timestamp: capacitorExif.DateTimeOriginal ?? exifReaderTags.exif?.DateTimeOriginal?.description,
            longitude: capacitorExif.GPS?.Longitude ?? exifReaderTags.gps?.Longitude ?? capacitorExif.GPSLongitude,
            latitude: capacitorExif.GPS?.Latitude ?? exifReaderTags.gps?.Latitude ?? capacitorExif.GPSLatitude,
          };

          // My Android phone reports GPSLongitude as "87/1,43/1,4601/11",
          // so let's handle strings of six numbers in any format.
          // Some inspiration: https://gis.stackexchange.com/a/273402
          for (const coord of ['longitude', 'latitude'] as (keyof typeof knownTags)[]) {
            const coordValue = knownTags[coord];
            if (typeof coordValue === 'string') {
              const numbersinValue = coordValue.match(/[\d.]+/g);
              if (numbersinValue?.length === 6) {
                const [degreesNumerator, degreesDenominator, minutesNumerator, minutesDenominator, secondsNumerator, secondsDenominator] = numbersinValue.map(parseFloat);
                const decimalValue = degreesNumerator / degreesDenominator + minutesNumerator / minutesDenominator / 60 + secondsNumerator / secondsDenominator / 3600;
                knownTags[coord] = decimalValue;
              } else {
                // We can pick up unrecognized formats in Sentry.
                console.error(`Couldn't parse EXIF ${coord} string ${JSON.stringify(coordValue)}`);
              }
            } else if (coordValue && typeof coordValue !== 'number') {
              console.error(`Couldn't parse EXIF ${coord} ${JSON.stringify(coordValue)}`);
            }

            // Capacitor doesn't process negative coordinates, so we need to switch them maunally.
            // ExifReader does this automatically.
            const cappedKey = coord.charAt(0).toUpperCase() + coord.slice(1);
            const coordRef = capacitorExif.GPS?.[`${cappedKey}Ref`] ?? capacitorExif[`GPS${cappedKey}Ref`];
            if (coordRef?.startsWith('W') && knownTags[coord] > 0) {
              knownTags[coord] *= -1;
            }
          }

          if (!this.photoMetadata.date && knownTags.timestamp !== undefined) {
            // These are colon- and space-separated, e.g. "1984:02:25 12:34:56".
            const timestampComponents = knownTags.timestamp.split(/\D/).map(parseFloat);

            // Months are zero-indexed!
            timestampComponents[1] -= 1;

            const timestamp = moment(timestampComponents);

            if (timestamp.isValid()) {
              this.photoMetadata.date = timestamp.format('YYYY-MM-DD');
              this.photoMetadata.time = timestamp.format('HH:mm');

              if (!newlyMounted) {
                this.postData.date = this.photoMetadata.date;
                this.postData.time = this.photoMetadata.time;
              }
            }
          }

          if (!this.photoMetadata.coordinates && knownTags.longitude !== undefined && knownTags.latitude !== undefined) {
            const lngLat = [knownTags.longitude, knownTags.latitude] as LngLat;
            const { features } = lngLat && await reverseGeocode(lngLat, { limit: 1 });
            const feature = features[0];

            if (feature) {
              this.photoMetadata.coordinates = lngLat;
              this.photoMetadata.location = feature;

              if (!newlyMounted) {
                this.postData.location = this.photoMetadata.location;
                this.postData.coordinates = this.photoMetadata.coordinates;
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      }

      // If we've found a date, but no location, and the date is basically right now,
      // assume the user has just taken the photo and get their location.
      if (this.photoMetadata.date && !this.photoMetadata.location && !newlyMounted) {
        const secondsSinceTimestamp = moment().diff(`${this.photoMetadata.date}T${this.photoMetadata.time}`, 'seconds');
        const photoIsBrandNew = secondsSinceTimestamp < 60;
        if (photoIsBrandNew) {
          const { lngLat, location } = await getActiveLocation();
          if (location) {
            this.photoMetadata.coordinates = lngLat;
            this.photoMetadata.location = location;
            this.postData.coordinates = this.photoMetadata.coordinates;
            this.postData.location = this.photoMetadata.location;
          }
        }
      }
    },

    futurePosted(fromTheFuture) {
      if (fromTheFuture) {
        const now = moment();
        this.postData.date = now.format('YYYY-MM-DD');
        this.postData.time = now.format('HH:mm');
      }
    },

    // Clear errors once we edit their related fields.
    ['postData.textBody']() { this.errors.noText = false; },
    ['postData.date']() { this.errors.noDate = false; },
    ['postData.time']() { this.errors.noTime = false; },
    ['postData.location']() { this.errors.noLocation = false; },
    ['postData.coordinates']() { this.errors.noCoordinates = false; },
  },

  async mounted() {
    await this.$store.dispatch('fetchInvestigations');
    this.loadLatestOrCreateNewDraft();
  },

  methods: {
    async loadLatestOrCreateNewDraft() {
      let userDraftKeys: string[] = [];
      try {
        const allDraftKeys = await localForage.keys();
        userDraftKeys = allDraftKeys.filter(key => key.startsWith(this.draftKeyPrefix)).sort();
      } catch (error) {
        console.error('Error listing draft post keys', error);
      }

      if (userDraftKeys.length === 0) {
        this.initializePost();
      } else {
        try {
          const mostRecentDraftKey = userDraftKeys[userDraftKeys.length - 1];
          this.postData = await localForage.getItem(mostRecentDraftKey) as DraftPost;

          if (this.postData.investigationIds === undefined) {
            this.postData.investigationIds = [];

            const legacyFormatPostData: any = { ...this.postData };

            if ('investigationId' in legacyFormatPostData) {
              this.postData.investigationIds.push(legacyFormatPostData.investigationId);
            }
          }

          if (!this.postData.textBody && this.postData.photos.length === 0 && this.formattedStructuredAnswers.length === 0) {
            // With no text or photos or structured answers, we should consider it new.
            this.initializePost();
          }
        } catch (error) {
          this.initializePost();
          console.error('Error reading draft post', error);
          try {
            userDraftKeys.forEach(key => localForage.removeItem(key));
          } catch (error) {
            console.error('Error clearing out draft post keys', error);
          }
        }
      }

      if (this.investigationIdFromQuery && !this.postData.investigationIds.includes(this.investigationIdFromQuery)) {
        this.postData.investigationIds.push(this.investigationIdFromQuery);
      }
    },

    async initializePost() {
      this.postData = {
        draftKey: Date.now().toString(36),
        textBody: '',
        weird: false,
        photos: [],
        date: '',
        time: '',
        location: this.postData.location ?? null,
        coordinates: this.postData.coordinates ?? null,
        investigationIds: [],
        structuredAnswers: {},
      };

      this.resetDateAndTime();
      this.resetInvestigations();

      await this.storeCurrentState();
    },

    async resetPost() {
      // @ts-ignore
      Object.keys(this.errors).forEach(key => this.errors[key] = false);

      Object.assign(this.postData, {
        textBody: '',
        weird: false,
        photos: [],
      });

      this.resetDateAndTime();
      this.resetInvestigations();

      await this.storeCurrentState();

      this.$router.push('#'); // Go to first step.

      this.confirmingReset = false;
    },

    resetDateAndTime() {
      const now = moment();
      this.postData.date = now.format('YYYY-MM-DD');
      this.postData.time = now.format('HH:mm');
    },

    resetInvestigations() {
      this.postData.structuredAnswers = {};

      this.postData.investigationIds = [];

      // E.g. automatically pick "flooding" with the param: ?partner=miami-flooding
      // const partnerInvestigationSlug = this.$store.state.partner?.split('-').pop();
      // const partnerInvestigation = this.investigations.find(investigation => investigation.slug === partnerInvestigationSlug);

      // if (partnerInvestigation) {
      //   this.postData.investigationIds.push(partnerInvestigation.id);
      // }
    },

    async storeCurrentState(): Promise<DraftPost | undefined> {
      try {
        return await localForage.setItem(this.draftKeyPrefix + this.postData.draftKey, this.postData);
      } catch (error) {
        console.error('Error storing draft post', error);
      }
    },

    async submitPost() {
      try {
        this.sizePosted = 0;
        this.saving = true;

        const valid = this.validateData();

        if (!valid) {
          return;
        }

        const selectedMediaCount = this.postData.photos.length;

        const payload: PostCreationPayload = {
          retry: 2,
          textBody: this.postData.textBody,
          weird: this.postData.weird,
          media: [],
          observedAt: moment(`${this.postData.date} ${this.postData.time}`).format(),
          lng: (this.postData.coordinates as LngLat)[0],
          lat: (this.postData.coordinates as LngLat)[1],
          addressComponents: parseAddressComponentsFromMBobj(this.postData.location as MBAddressObj),
          investigation: this.postData.investigationIds,
          structuredAnswers: this.formattedStructuredAnswers,
        };

        let previousFileSizes = 0;

        for (const file of this.postData.photos) {
          const { data: media } = await this.$store.dispatch('uploadMedia', {
            file: file,
            onUploadProgress: (progress: ProgressEvent) => {
              this.sizePosted = previousFileSizes + progress.loaded;
            },
          });
          previousFileSizes += file.size;
          payload.media.push({
            id: String(media.id),
            type: media.mediaType === 'video' ? media.mediaType : 'image',
          });
        }

        const creation = await this.$store.dispatch('createPost', { ...payload, selectedMediaCount });

        if (creation.error) {
          throw creation.error;
        } else {
          this.$store.dispatch('alertUser', {
            type: 'info',
            message: this.successMessage,
            timeout: -1,
          });

          try {
            await localForage.removeItem(this.draftKeyPrefix + this.postData.draftKey);
          } catch (error) {
            console.error('Error removing old draft post', error);
          }

          await this.initializePost();

          this.goToPosts(payload);
        }
      } catch (error: any) {
        let message = this.$t('steppedPostCreation.errors.unknown');

        if (error.message === 'offline') {
          message = this.$t('steppedPostCreation.errors.offline');
        } else if (error.message.toLowerCase().includes('network')) {
          message = this.$t('steppedPostCreation.errors.network');
        }

        this.$store.dispatch('alertUser', { type: 'error', message });
      } finally {
        this.saving = false;
        this.sizePosted = 0;
      }
    },

    validateData(): boolean {
      if (!this.postData.textBody) {
        this.errors.noText = true;
      }

      if (!this.postData.date) {
        this.errors.noDate = true;
      }

      if(!this.postData.time) {
        this.errors.noTime = true;
      }

      if (!this.postData.location) {
        this.errors.noLocation = true;
      }

      if (!this.postData.coordinates) {
        this.errors.noCoordinates = true;
      }

      return Object.values(this.errors).filter(Boolean).length === 0;
    },

    goToPosts(payload: any) {
      const feedQuery = {
        near: [payload.lng, payload.lat, '20mi'].join(','),
      } as any;

      const wasBackPosted = moment().diff(payload.observedAt, 'minutes') >= 15;

      if (wasBackPosted) {
          feedQuery.toDate = payload.observedAt;
      }

      this.$router.push({ name: 'posts', query: feedQuery });
    },
  },
});
